@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX70gfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX7kgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX7UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX7EgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX60gfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX50gfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX58gfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX5kgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX5UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX40gfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX4UgfjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX7kgvjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX60gvjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX6EgvjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX5kgvjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX40gvjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX4EgvjL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto Serif';
  font-style: normal;
  font-weight: 900;
  font-stretch: normal;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/robotoserif/v13/R71RjywflP6FLr3gZx7K8UyuXDs9zVwDmXCb8lxYgmuii32UGoVldX7kg_jL4-3sMM_kB_qXSEXTJQCFLH5-_bcEloMqp6c.ttf) format('truetype');
}
